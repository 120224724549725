import React from 'react';
import Navbar from "../components/Navbar.component";
import Background from "../components/Background.component";
import ThemeState from "../contexts/theme/ThemeState";



// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line
const analytics = getAnalytics(app);


const App = () => {
  return (
      <ThemeState>
          <Background>
              <Navbar name={'Empty react project'}/>
              <main className={'container text-center'}>
                  <h1>This is an empty react project <span className={'badge badge-success'}>v. 1.0</span></h1>
                  <h2>packets installed: <span className={'badge badge-primary'}>Bootstrap 4.4.1</span></h2>
                  <h2>features included: <span className={'badge badge-secondary'}>dark mode</span></h2>
                  <h2>NODE_ENV is <b>{process.env.NODE_ENV}</b></h2>
                  <h2>Deployment timestamp is <b>{process.env.REACT_APP_DEPLPOMENT_TIMESTAMP}</b></h2>
                  <h2>API base URL is <b>{process.env.REACT_APP_API_BASE_URL}</b></h2>
              </main>
          </Background>
      </ThemeState>
  );
};

export default App;
